<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> <strong>All Customers </strong> </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th scope="col" class="text-left">Name</th>
                  <th scope="col" class="text-left">Email</th>
                  <th scope="col" class="text-left">Contact no.</th>
                  <th scope="col" class="text-left">Address</th>
                  <th
                    scope="col"
                    class="text-center"
                    v-if="
                      can('customer_read') ||
                      can('customer_update') ||
                      can('customer_delete')
                    "
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="text-center pt-5" v-if="isLoading">
                <tr>
                  <td class="text-center" colspan="8">
                    <CSpinner color="primary" size="lg" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="customer in customers" :key="customer.id">
                  <td class="text-left">
                    {{ customer.first_name + " " + customer.last_name }}
                  </td>
                  <td class="text-left">{{ customer.email }}</td>
                  <td class="text-left">{{ customer.contact_no }}</td>
                  <td class="text-left">{{ customer.address }}</td>
                  <td
                    class="text-center"
                    v-if="
                      can('customer_read') ||
                      can('customer_update') ||
                      can('customer_delete')
                    "
                  >
                    <CButtonGroup size="sm">
                      <CButton
                        color="primary"
                        @click="showCustomer(showModal, customer)"
                        v-if="can('customer_read')"
                      >
                        View</CButton
                      >
                      <CButton
                        color="info"
                        @click="editCustomer(customer.id)"
                        v-if="can('customer_update')"
                      >
                        Edit</CButton
                      >
                      <CButton
                        @click="deleteCustomer(deleteModal, customer)"
                        color="danger"
                        v-if="can('customer_delete')"
                        >Delete</CButton
                      >
                    </CButtonGroup>
                  </td>
                </tr>
              </tbody>
            </table>
            <CPagination
              v-if="!isLoading"
              :activePage.sync="pagination.current"
              :pages="pagination.last"
              align="center"
              @update:activePage="getResults"
            />
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <CustomerView />
    <CustomerDelete />
  </CRow>
</template>

<script>
import { mapState } from "vuex";
const CustomerView = () => import("@/views/Customer/CustomerView");
const CustomerDelete = () => import("@/views/Customer/CustomerDelete");

export default {
  name: "Customers",
  components: {
    CustomerDelete,
    CustomerView,
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("customers/getCustomers");
  },
  computed: {
    ...mapState({
      customers: (state) => state.customers.customers,
      pagination: (state) => state.customers.pagination,
      isLoading: (state) => state.customers.isLoading,
      deleteModal: (state) => state.customers.deleteModal,
      showModal: (state) => state.customers.showModal,
    }),
  },
  methods: {
    getResults(page = 1) {
      this.$store.dispatch("customers/getCustomers", page);
    },
    can(permisson) {
      return this.$store.state.userPermissions
        ? this.$store.state.userPermissions.indexOf(permisson) >= 0
          ? true
          : false
        : null;
    },
    deleteCustomer(bool, customer) {
      this.$store.commit("customers/DELETE_MODAL", {
        bool: bool,
        customer: customer,
      });
    },
    showCustomer(bool, customer) {
      this.$store.commit("customers/SHOW_MODAL", {
        bool: bool,
        customer: customer,
      });
    },
    editCustomer(customer_id) {
      this.$store.dispatch("customers/editCustomer", {
        customer_id: customer_id,
      });
      this.$router.push({ name: "Edit Customer" });
    },
  },
};
</script>
